import { useState, useEffect } from "react";
import fbimage from "../assets/images/round-facebook-button.png";

export default function CustomFooter() {
  const [isMobile, setIsMobile] = useState(false);
  const [fbLink, setFbLink] = useState('');

  useEffect(() => {
    setIsMobile( ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && 'ontouchstart' in document.documentElement) );
    // console.log(`navigator.userAgent = ${'\n'}${navigator.userAgent}`);
    // console.log(`isMobile = ${isMobile}`);

    const thisFBLink = isMobile
      ? "fb://profile/117190996328794"
      : "https://www.facebook.com/StrangersDuo";

    setFbLink(thisFBLink);
  }, [isMobile]);

  return (
    <>
      <footer>
        <div>
          <a href={fbLink}><img src={fbimage} /></a>
        </div>
      </footer>
    </>
  )
}