import { useState, useEffect } from "react";
import Papa from 'papaparse';
const strangersDuo = "/strangers-duo2.png";

export default function EventSchedule({ data }) {
  const [ jsonData, setJsonData ] = useState([])

  useEffect(() => {
    let csvText = '';
    fetch( './schedule.csv' )
    .then( response => response.text() )
    .then( responseText => {
      csvText = responseText;
      Papa.parse(csvText, {
        header: true,
        complete: (results) => {
          setJsonData(results.data);
        }
      })
    });
  }, []);

  return (
    <>
      <div className="header">
        <img src={strangersDuo} alt="strangers duo" aria-hidden="true" />
      </div>
      <h1>Strangers Event Schedule</h1>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Event</th>
            <th>Location</th>
          </tr>
        </thead>
        <CustomTableTbodyRows data={jsonData} />
      </table>
    </>
  );
}

function CustomTableTbodyRows({ data }) {
  const today = new Date();
  const maxListingDate = new Date(today);
  maxListingDate.setDate(today.getDate() + (90));

  const optionsShort = { weekday:'short', month:'short', day:'numeric', hour: 'numeric', minute: 'numeric' };
  const optionsLong = { weekday:'long', month:'long', day:'numeric', hour: 'numeric', minute: 'numeric' };
  let displayList = [];

  const prettyDate = (dt) => {
    // Short date will not have ' at ' in the string.
    return (dt.includes(' at '))
      // Long date
      ? dt.replace(/^(\w+,\s\w+\s\d+)\sat\s(\d+:\d+)\s(AM|PM)$/, (a, b, c, d) => { return `${b} @ ${c}${d}`})
      // Short date
      : dt.replace(/^(\w+,\s\w+\s\d+),\s(\d+:\d+)\s(AM|PM)/,(a, b, c, d) => { return `${b} @ ${c}${d}` });
  }

  data.forEach((evt) => {
    if (evt.Date.length) {
      const dateTime = evt.Date + " " + evt.Time;
      const eventDate = new Date(dateTime);
      if (eventDate >= today && eventDate <= maxListingDate) {
        const formatterShort = new Intl.DateTimeFormat('en-US', optionsShort);
        const formatterLong = new Intl.DateTimeFormat('en-US', optionsLong);
        const formattedDateShort = prettyDate(formatterShort.format(eventDate)); 
        const formattedDateLong = prettyDate(formatterLong.format(eventDate)); 
        displayList.push({...evt, Date: {short: formattedDateShort, long: formattedDateLong}});
      }
    }
  });

  return (
    <tbody>
      {
        displayList.map((row,index) => (
          <CustomTableTbodyRow key={index} row={row} />
        ))
      }
    </tbody>
  );
}

function CustomTableTbodyRow({ row }) {
  return (
    <tr key={row.Date}>
      <td className="datetime">
        <span className="short">{row.Date.short}</span>
        <span className="long">{row.Date.long}</span>
      </td>
      <td>{row.Event}</td>
      <td><CustomMapLink location={row.Location} /></td>
    </tr>
  )
}

function CustomMapLink ({ location }) {
  const googleAPIPrefix = 'http://maps.google.com/?q=';
  const mapLink = googleAPIPrefix + location;

  return (
    <a href={mapLink}>{location}</a>
  )
}
